import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { axiosconn, userCon, UserProvider } from "../util/usercontext";
import Footer from './footer';


function EditBusiness() {

    const navigate = useNavigate();
    const { user, logout } = useContext(userCon);
    const [updateForm, setFormData] = useState({
        uid: user.uid,
        fname: user.fname,
        lname: user.lname,
        uname: user.username,
        email: user.email,
        phone: user.phone_number,
        city: '',
        state: '',
    });

    const onChange = (e) => {
        setFormData({
            ...updateForm,
            [e.target.name]: e.target.value
        })
    }

    const editprofile = async (e) => {
        e.preventDefault();
        console.log(updateForm);
        try {
            const ipdata = JSON.stringify(updateForm);
            const loginToken = localStorage.getItem('token');
            if (loginToken) {
                const { data } = await axiosconn.post('editbusinessprofile', ipdata, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + loginToken
                    }
                });
                if (data.success) {
                    // e.target.reset();
                    alert('Details Updated Successfully');
                    navigate("/businessProfile");
                }
                else if (!data.success && data.message) {
                    alert(data.message);
                }
            }
        }
        catch (err) {
            console.log(err);
            alert('Server Error!');
        }

    }

    const deleteprofile = async (e) => {
        e.preventDefault();
        try {
            const loginToken = localStorage.getItem('token');
            if (loginToken) {
                const { response } = await axiosconn.post('deletebusinessprofile', {
                    params: {
                        'id': user.uid,
                    },headers: {
                        'Authorization': 'Bearer ' + loginToken,
                    }
                });

                if (response.success) {
                    e.target.reset();
                    alert('Account Deleted');
                    navigate("/signin");
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const onlogout = (e) => {
        logout();
    };
    return (
        <div>
            <nav className="header">
                <Link to="/" className="site-title" style={{ fontWeight: 'bold', color: 'white', fontSize: 30 }}>Mav Market</Link>
                <div className="dropdown" >
                    <i className="ggprofile"></i>
                    <div className="dropdownContent">
                        <a href="/business">DashBoard</a>
                        <a href="/products">Products</a>
                        <a href="/addproduct">Add Product</a>
                        <a href="/addad">Add Ad</a>
                        <a href="/login" onClick={onlogout}>Logout</a>
                    </div>
                </div>
                <div className="header-right">
                    <a href="/home" style={{ color: 'white' }} >Home</a>
                    <a href="/about" style={{ color: 'white' }} >About</a>
                    <a href="/service" style={{ color: 'white' }} >Service</a>
                    <a href="http://dmp4205.uta.cloud/mavmarket/blog/" style={{ color: 'white' }} >Blog</a>
                    <a href="/contact" style={{ color: 'white' }} >Contact</a>
                    <a href="/login" style={{ color: 'white' }} >Login/Register</a>
                </div>
            </nav>
            <div className="content row">
                <form className="add-product" onSubmit={editprofile}>
                    <div className="form-row">
                        <div className="form-labels">
                            First Name:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="fname" name="fname" defaultValue={user.fname} onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Last Name:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="lname" name="lname" defaultValue={user.lname} onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Username:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="uname" name="lname" defaultValue={user.username} onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Email:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="email" name="email" defaultValue={user.email} onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Phone Number:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="phone" name="phone" defaultValue={user.phone_number} onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            City:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="city" name="city" onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            State:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="state" name="state" onChange={onChange} />
                        </div>
                    </div>
                    <div align="center">
                        <button className='submit' style={{marginRight: "5em"}} onClick={editprofile}>Edit Profile</button>
                        <button className='delete' style={{marginRight: "10em"}} onClick={deleteprofile}>Delete Profile</button>
                    </div>
                </form>
            </div>
            <Footer style={{ position: 'absolute' }} />
        </div>
    )
}

export default EditBusiness;